header .grid-container {
  display: grid;
  grid-template-columns: 1fr 6fr;
  align-items: center;
}

header .grid-container,
main section {
  width: 90%;
  margin: 0 auto;
}

header .grid-container nav ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

section {
  padding-top: 20px;
}

header .grid-container nav > ul > a li,
header .grid-container nav > ul > button li,
header .grid-container nav > ul > li {
  margin-left: 10px;
  z-index: 5;
  padding-left: 0;
}

header .grid-container nav ul > li {
  position: relative;
}

header .grid-container nav ul li ul {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  left: 0;
  width: 125px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

header .grid-container nav ul li ul li {
  padding: 5px;
}

header .grid-container nav ul li:hover ul {
  display: block;
}

header .grid-container nav ul a,
header .grid-container nav ul button,
header .grid-container nav ul > li {
  color: #3493db;
  text-decoration: none;
}

header .grid-container nav ul button {
  background: none;
  border: 0;
  padding: 0;
}

form input {
  width: 100%;
  border: 0;
  border-bottom: 3px solid #3493db;
  padding: 5px 10px;
}

form label {
  display: grid;
  grid-template-columns: 1fr 22fr;
  align-items: center;
  margin-bottom: 15px;
}

form label svg {
  width: 100%;
  margin-right: 5px;
  color: #3493db;
}

.MuiListItem-root .MuiLink-root:hover {
  text-decoration: none;
}

.error span {
  color: #ff501c;
  font-weight: bold;
}
/* #FDFF32 */

.main-logo {
  width: 90%;
  margin-top: -25px;
}

.particle {
  background: #ff9100;
  width: 100%;
  height: 94vh;
  z-index: 1;
}

.App {
  position: relative;
}

.attract {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.header {
  z-index: 50;
}

.attract-main {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.country-select {
  margin-top: 15px;
}
